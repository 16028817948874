$product-offer-background: color(dark);
$product-offer-padding: 0;

// Icon
$product-offer-icon-width--large: rem-calc(170);
$product-offer-icon-padding: 0;

// Title
$product-offer-title-font-weight: normal;
$product-offer-title-font: #{rem-calc(14)} / 1.45 $font-primary;
$product-offer-title-text-transform: lowercase;
$product-offer-title-font--large: $product-offer-title-font;
$product-offer-title-color: color(light);

// Description
$product-offer-description-a-color: color(light);
$product-offer-description-font: 300 #{rem-calc(14)} / 1.45 $font-primary;
$product-offer-description-padding: rem-calc(0 20);
$product-offer-description-padding--large: rem-calc(0 30);
$product-offer-description-color: color(light);
$product-offer-description-text-transform: lowercase;

@import "@lora/05-components/product/product-offer";