$video-asset-icon-height: rem-calc(32);
$video-asset-icon-width: rem-calc(32);
$video-asset-icon-color: color(light);
$video-asset-info-name-font: normal #{rem-calc(14)} / 1.45 $font-primary;
$video-asset-info-name-font--large: $video-asset-info-name-font;
$video-asset-info-name-text-transform: lowercase;
$video-asset-info-description-font: 300 #{rem-calc(14)} / 1.45 $font-primary;
$video-asset-info-description-font--large: $video-asset-info-description-font;
$video-asset-info-description-text-transform: lowercase;
$video-asset-info-margin: rem-calc(14 0 0);
$video-asset-info-margin--large: $video-asset-info-margin;
$video-asset-info-name-margin: rem-calc(0 0 8);
$video-asset-info-name-margin--large: $video-asset-info-name-margin;

@import "@lora/05-components/video-asset";