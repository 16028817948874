$search-refinement-applied-qty-font: 300 #{rem-calc(10)} / 2 $font-primary;
$search-refinement-applied-values-font: #{rem-calc(12)} / #{rem-calc(18)} $font-primary-light;
$search-refinement-border-bottom: 1px solid color(border);

$search-refinement-clear-text-transform: lowercase;
$search-refinement-clear-font: normal #{rem-calc(14)} / 1.4 $font-primary;
$search-refinement-clear-margin: rem-calc(0 0 20);

$search-refinement-content-padding--medium-down: rem-calc(15 25 18);
$search-refinement-content-padding--large: rem-calc(0 0 28);
$search-refinement-content-range-padding--medium-down: $search-refinement-content-padding--medium-down;
$search-refinement-content-background--medium-down: color(secondary);

$search-refinement-title-expanded-border-bottom: $search-refinement-border-bottom;
$search-refinement-title-expanded-color: color(primary);
$search-refinement-title-expanded-font: normal #{rem-calc(12)} / #{rem-calc(16)} $font-tertiary;
$search-refinement-title-expanded-padding: rem-calc(17 20 18);
$search-refinement-title-expanded-padding--large: rem-calc(15 5 15 14);

$search-refinement-title-font: 500 #{rem-calc(12)} / #{rem-calc(16)}  $font-tertiary;
$search-refinement-title-font--large: $search-refinement-title-font;
$search-refinement-title-padding: rem-calc(17 20 18);
$search-refinement-title-padding--large: rem-calc(18 0 17);

$search-refinement-content-wrapper-background-color: color(secondary);

@import "@lora/05-components/search/search-refinement";