$simple-navigation-back-link-color: color(light);
$simple-navigation-back-link-text-font: 300 #{rem-calc(10)} / 1.6 $font-primary;
$simple-navigation-back-link-padding: rem-calc(12 17 12 10);
$simple-navigation-background: color(organism-background);
$simple-navigation-color: color(light);
$simple-navigation-font: 300 #{rem-calc(12)} / #{rem-calc(16)} $font-primary-light;
$simple-navigation-font--large: $simple-navigation-font;
$simple-navigation-item-active-font-weight: normal;
$simple-navigation-text-transform: lowercase;
$simple-navigation-link-padding: rem-calc(14 9);
$simple-navigation-subcategories-link-color: color(light);
$simple-navigation-subcategories-active-link-color: color(light);
$simple-navigation-subcategories-link-padding: rem-calc(14 10 14 20);
$simple-navigation-back-link-icon: chevron2-left;
$simple-navigation-back-link-icon-height: rem-calc(10);
$simple-navigation-back-link-icon-width: rem-calc(5);
$simple-navigation-anchors-link-active-color: color(secondary);

@import "@lora/05-components/simple-navigation";