@import "@root/pages/product";

.c-product-detail-image__mosaic-services {
    &.m-primary,
    &.m-secondary {
        display: flex;
        flex-direction: column-reverse;
    }

    &.m-secondary {
        margin: rem-calc(0);

        .c-button {
            margin: rem-calc(0 auto 0.625);
        }
    }

    .c-button {
        width: rem-calc(300);
        margin: rem-calc(0 auto 0.625);
        padding: rem-calc(17 4);
        display: block;

        &:last-child {
            margin-right: rem-calc(auto);
        }
    }
}