$search-refinements-header-border-top--large: 1px solid color(border);
$search-refinements-header-back-and-results-font: normal #{rem-calc(14)} / #{rem-calc(20)} $font-tertiary;
$search-refinements-header-back-and-results-text-transform: lowercase;
$search-refinements-header-padding--large: rem-calc(17 0);

$search-refinements-category-name-border-top: 1px solid color(border);
$search-refinements-category-name-font: normal #{rem-calc(12)} / #{rem-calc(16)} $font-tertiary;
$search-refinements-category-name-text-transform: lowercase;
$search-refinements-category-name-padding: rem-calc(17 0 16);

$search-refinements-header-title-text-transform: lowercase;
$search-refinements-header-title-font: $search-refinements-header-back-and-results-font;
$search-refinements-header-title-font--large: 300 #{rem-calc(14)} / #{rem-calc(16)} $font-tertiary;
$search-refinements-header-title-text-transform--large: lowercase;
$search-refinements-header-title-margin--large: 0;

@import "@lora/05-components/search/search-refinements";