$quote-font: normal #{rem-calc(18)} / 1.3 $font-secondary;
$quote-text-transform: lowercase;
$quote-margin: rem-calc(60) auto rem-calc(70);
$quote-margin--large: rem-calc(70) auto rem-calc(115);
$quote-open-font: normal #{rem-calc(35)} / 1 $font-secondary;
$quote-close-font: $quote-open-font;
$quote-author-font: 300 #{rem-calc(12)} / 1.3 $font-primary;
$quote-author-before-font: normal #{rem-calc(12)} / 1.5 $font-primary;
$quote-author-before-display: none;

@import "@lora/05-components/quote";