$selected-products-item-active-border: 2px solid color(dark);
$selected-products-item-active-border--large: $selected-products-item-active-border;
$selected-products-item-border: 1px solid color(element-background);
$selected-products-item-border--large: $selected-products-item-border;

$selected-products-title-font: normal #{rem-calc(12)} / #{rem-calc(16)} $font-primary;
$selected-products-title-font--large: normal #{rem-calc(14)} / #{rem-calc(20)} $font-primary;
$selected-products-title-text-transform: lowercase;
$selected-products-title-text-transform--large: $selected-products-title-text-transform;
$selected-products-title-margin: rem-calc(10 0);

$selected-products-total-background: color(light);
$selected-products-total-background--large: color(light);
$selected-products-total-font: normal #{rem-calc(12)} / #{rem-calc(16)} $font-primary;
$selected-products-total-font--large: 300 #{rem-calc(14)} / #{rem-calc(20)} $font-primary;
$selected-products-total-color--large: color(text-secondary);
$selected-products-total-text-transform: lowercase;
$selected-products-total-text-transform--large: lowercase;

// Actions
$selected-products-actions-margin--large: rem-calc(20 0 0);

@import "@lora/05-components/selected-products";