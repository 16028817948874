// Tabs specific style
$product-swatches-grouped-tab-active-background: color(primary-active);
$product-swatches-grouped-tab-font: 300 #{rem-calc(12)} / #{rem-calc(16)} $font-primary-light;
$product-swatches-grouped-tab-active-border: 0;
$product-swatches-grouped-tab-border-radius: 0;
$product-swatches-grouped-tab-padding: rem-calc(7 10 5);
$product-swatches-grouped-tab-padding--large: $product-swatches-grouped-tab-padding;
$product-swatches-grouped-tabs-control-items-margin: rem-calc(15 0);
$product-swatches-grouped-tabs-content-margin: rem-calc(15 0 0 0);
$product-swatches-grouped-tab-margin: rem-calc(0 10 0 0);
$product-swatches-grouped-tab-first-margin: rem-calc(0 10 0 0);
$product-swatches-grouped-tab-text-transform: lowercase;
// Swatches Group
$product-swatches-grouped-swatch-margin: rem-calc(0 9.5 16 0);
$product-swatches-grouped-swatch-margin--large: $product-swatches-grouped-swatch-margin;
$product-swatches-grouped-group-margin: rem-calc(0 0 14 0);
$product-swatches-grouped-group-margin--large: $product-swatches-grouped-group-margin;

@import "@lora/05-components/product/product-swatches-grouped";