$product-variations-carousel-link-height: rem-calc(60);
$product-variations-carousel-link-min-width--large: auto;
$product-variations-carousel-link-color: color(dark);
$product-variations-carousel-link-font: normal #{rem-calc(12)} / 1.3 $font-primary;
$product-variations-carousel-link-selected-color: color(dark);
$product-variations-carousel-link-background: color(light);
$product-variations-carousel-link-border: 1px solid color(disabled);
$product-variations-carousel-link-selected-border: 2px solid color(dark);
$product-variations-carousel-link-selected-background: color(light);
$product-variations-carousel-link-border-radius: rem-calc(0);
$product-variations-carousel-link-selected-font: 400 #{rem-calc(12)} / 1.3 $font-primary;

// Disabled state
$product-variations-carousel-link-disabled-opacity: 0.3;
$product-variations-carousel-link-disabled-background: $product-variations-carousel-link-background;
$product-variations-carousel-link-disabled-color: $product-variations-carousel-link-color;
$product-variations-carousel-link-disabled-border: $product-variations-carousel-link-border;

//Price
$product-variations-carousel-price-font-weight: 300;
$product-variations-carousel-price-color: color(text-secondary);

@import "@lora/05-components/product/product-variations-carousel";