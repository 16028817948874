$video-library-item-icon-color: color(light);
$video-library-item-icon-height: rem-calc(32);
$video-library-item-icon-height--large: $video-library-item-icon-height;
$video-library-item-icon-width: rem-calc(32);
$video-library-item-icon-width--large: $video-library-item-icon-width;
$video-library-item-name-font: 300 #{rem-calc(14)} / 1.45 $font-primary;
$video-library-item-name-font--large: normal #{rem-calc(14)} / 1.45 $font-primary;
$video-library-item-name-text-transform: lowercase;
$video-library-item-name-margin--large: rem-calc(0 0 8);
$video-library-item-description-font--large: 300 #{rem-calc(14)} / 1.45 $font-primary;
$video-library-item-description-text-transform--large: lowercase;
$video-library-item-active-border: 1px solid color(light);
$video-library-item-active-border--large: none;
$video-library-item-background--medium-down: transparent;
$video-library-item-border-radius--medium-down: 0;
$video-library-item-image-border: none;
$video-library-item-border--medium-down: 1px solid color(light);
$video-library-playlist-max-height--large: rem-calc(545);
$video-library-playlist-title-text-transform: lowercase;
$video-library-list-height--large: rem-calc(545);
$video-library-item-margin--large: rem-calc(0 0 20 0);

@import "@lora/05-components/video-library";